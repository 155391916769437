import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 79,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primaryFont: "Roboto, sans-serif",
    secondaryFont: "PT Serif, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#0C0C0D",
      light: "#0F1418",
      dark: "#000000",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#6D97E5",
      light: "#e9effc",
      dark: "#6D97E5",
    },
    tertiary: {
      main: "#FFFFFF",
    },
    text: {
      primary: "#0F1418",
      secondary: "#FFFFFF",
      tertiary: "#6D97E5",
      disabled: "#0F1418",
      hint: "#0F1418",
    },
  },
  typography: {
    fontFamily: `"Roboto","PT Serif"`,
  },
})
export default theme
